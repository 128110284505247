/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    strong: "strong",
    pre: "pre",
    code: "code",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Examples"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Option One")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const date = new Date()\nconsole.log(date) // Tue Feb 15 2022 05:33:57 GMT-0700 (Mountain Standard Time)\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Option Two")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const date = new Date().toDateString()\nconsole.log(date) // Tue Feb 15 2022\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Option Three")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const options = {\n  timeZone: \"America/New_York\",\n  month: \"short\",\n  day: \"numeric\",\n  hour: \"numeric\",\n  minute: \"numeric\",\n}\nconst date = new Date().toLocaleDateString(\"en-us\", options)\nconsole.log(date) // Feb 15, 7:33 AM\n")), "\n", React.createElement(_components.h3, null, "References"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://flaviocopes.com/how-to-format-number-as-currency-javascript/"
  }, "How to format a number as a currency value in JavaScript")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
